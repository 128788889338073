import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';

const AltemateYou = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    
    }, []);
  const [visionStatements, setVisionStatements] = useState({});
  const uid = localStorage.getItem("user_id");
  const [lifeAreas, setLifeAreas] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleChange = (e, key, field) => {
    const { value } = e.target;
    setVisionStatements({
      ...visionStatements,
      [key]: {
        ...visionStatements[key],
        [field]: value,
      },
    });
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      handleSubmit(key);
    }, 2000); // Adjust the delay as needed

    setDebounceTimer(newTimer);
  };

  
  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_record_ultimate_assessment}`, 
        formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const lifeAreasData = response.data.data;
        setLifeAreas(lifeAreasData);
        
        const initialVisionStatements = {};
        lifeAreasData.forEach(area => {
          if (area.data && area.data.length > 0) {
            const visionData = area.data[0];
            initialVisionStatements[area.category_id] = {
              what_is_right: visionData.what_is_right,
              what_is_wrong: visionData.what_is_wrong,
              what_do_i_need_answers: visionData.what_do_i_need_answers,
            };
          } else {
            initialVisionStatements[area.category_id] = {
              what_is_right: '',
              what_is_wrong: '',
              what_do_i_need_answers: '',
            };
          }
        });
        setVisionStatements(initialVisionStatements);
       
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch role model data error', error);
      toast.error("Failed to fetch role model data.");
    }
  };

  const handleSubmit = useCallback(async (key) => {
    const visionStatement = visionStatements[key];
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('category_id', key);
    formDataToSend.append('what_is_right', visionStatement?.what_is_right || '');
    formDataToSend.append('what_is_wrong', visionStatement?.what_is_wrong || '');
    formDataToSend.append('what_do_i_need_answers', visionStatement?.what_do_i_need_answers || '');
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_updated_ultimate_assessment}`, 
        formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData(); // Fetch updated data after submission
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Add vision statement error', error);
      toast.error("Failed to add vision statement.");
    }
  } ,[visionStatements, uid]); 

  useEffect(() => { 
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="align-items-center justify-content-between d-flex">
                  <h5>My Assessment Actions</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Area of Life</th>
                      <th scope="col">What is right? (Optimize)</th>
                      <th scope="col">What is wrong? (Change)</th>
                      <th scope="col">What do I need answers to? (Clarify)</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {lifeAreas.map((area) => (
                      <tr key={area.category_id}>
                        <td><b>{area.name}</b></td>
                        <td>
                          <textarea
                            className="form-control vision-text-area"
                            name={area.category_id}
                            placeholder="Insert Here..."
                            value={visionStatements[area.category_id]?.what_is_right || ''}
                            onChange={(e) => handleChange(e, area.category_id, 'what_is_right')}
                          />
                        </td>
                        <td>
                          <textarea
                            className="form-control vision-text-area"
                            name={area.category_id}
                            placeholder="Insert Here..."
                            value={visionStatements[area.category_id]?.what_is_wrong || ''}
                            onChange={(e) => handleChange(e, area.category_id, 'what_is_wrong')}
                          />
                        </td>
                        <td>
                          <textarea
                            className="form-control vision-text-area"
                            name={area.category_id}
                            placeholder="Insert Here..."
                            value={visionStatements[area.category_id]?.what_do_i_need_answers || ''}
                            onChange={(e) => handleChange(e, area.category_id, 'what_do_i_need_answers')}
                          />
                        </td>
                        {/* <td>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit(area.category_id)}
                          >
                            Save
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AltemateYou;
