import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import YourStory from "./personalBrandingTabs.js/yourStory";
import DevelopYourStory from "./personalBrandingTabs.js/developYourStory";
import TellYourStory from "./personalBrandingTabs.js/tellYourStory";
import FreshStory from "./personalBrandingTabs.js/freshStory";
import API, { isSubscribedAccountant } from "../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const PersonalBranding = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("What's Your Story");
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "Personal Branding");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
                if (response.data.sub_category.length > 0) {
                    setActiveTab(response.data.sub_category[0].sub_category);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };

    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };

    return (
        <Container fluid={true}>
      
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabChange}
                id="justify-tab-example"
                className="border-tab nav-primary mb-3"
                justify
            >
                
                {tabs.map(tab => (
                    <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                        {getTabContent(tab.sub_category)}
                    </Tab>
                ))}
            </Tabs>

        </Container>
    );
}

const getTabContent = (tabName) => {
    switch (tabName) {
        case "What's Your Story":
            return <YourStory />;
        case 'Develop Your Story':
            return <DevelopYourStory />;
        case 'Market Tell Your Story':
            return <TellYourStory />;
        case 'Nurture Your Story':
            return <FreshStory />;
        default:
            return null;
    }
};

export default PersonalBranding;
