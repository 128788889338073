import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container, Nav, NavItem, Tab, TabContent, TabPane, Tabs } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelfReflectionTabS from "./selfReflectionTabs/selfReflectiontevb";
import Perception from "./selfReflectionTabs/perception";
import Accomplishment from "./selfReflectionTabs/accomplishments";
import Reflection from "./selfReflectionTabs/reflection/reflection";
import FinishSentence from "./selfReflectionTabs/finiashSentence";


const SelfReflection = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('Self-Reflection');
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);


    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "Self Reflection");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch tabs.");
        }
    };


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };

    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };


    return (
        <>
            <Container fluid>
                    <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                        // defaultActiveKey="Self-Reflection"
                        id="justify-tab-example"
                        className="border-tab nav-primary mb-3"
                        justify
                    >
                        {tabs.map(tab => (
                            <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                                {getTabContent(tab.sub_category)}
                            </Tab>
                        ))}
                    </Tabs>
                    </Container>
        </>
    );
};

const getTabContent = (tabName) => {
    switch (tabName) {
        case 'Self-Reflection':
            return <SelfReflectionTabS />;
        case 'My Perception':
            return <Perception />;
        case 'Accomplishments':
            return <Accomplishment />;
        // case 'Reflection':
        //     return <Reflection />;
        case 'Finish The Sentence':
            return <FinishSentence />;
        default:
            return null;
    }
};

export default SelfReflection;
