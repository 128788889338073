import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import AltemateYou from "./altimate-you.js";

const LifeAssessment = () => {
  const navigate = useNavigate();
  const uid = localStorage.getItem('user_id') || '';
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
      return;
    }
  }, []);

  useEffect(() => {
    const storedFormData = localStorage.getItem('lifeWheelFormData');
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('main_category_id', '2');
    formDataToSend.append('sub_category_id', '4');

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getquestionbycatidsubcatidmyreality}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.question);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[`rating_${question.id}`] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message || 'Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to fetch questions. Please try again later.');
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    // Save the updated form data to localStorage
    localStorage.setItem('lifeWheelFormData', JSON.stringify(updatedFormData));

    // Trigger the API call for updating the answer
    const formDataToSend = new FormData();
    questions.forEach((question, index) => {
      formDataToSend.append(`question_id[${index + 1}]`, question.id);
      formDataToSend.append(`answer[${index + 1}]`, updatedFormData[`rating_${question.id}`] || '');
    });
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.addupdateanswersmyreality}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || 'Failed to update answers');
      }
    } catch (error) {
      console.error('Error updating answers:', error);
      toast.error('Failed to update answers. Please try again later.');
    }
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6 align-items-center d-flex">
                    <h5>My Life Assessment</h5>
                  </div>
                </div>
              </div>
              <form className="form theme-form">
                <div className="card-body">
                  <div className="textnots-container mb-3">
                    <p className="textnots-text">
                      Would you like to improve your life or identify areas of your life that you can improve to increase your happiness? One tool that we regularly use with our clients who are looking to increase their personal happiness and success is the Life Assessment. This is a very simple yet powerful tool to help you identify the areas of your life that you have neglected and need to prioritize more in future. It takes less than 10 minutes to complete.
                    </p>
                    <p className="textnots-text">
                      Give yourself a score of 1 to 10 depending on how satisfied you currently are in relation to each area on the assessment where 1 is pretty much as unhappy as you can be whereas 10 is the perfect satisfaction score. As you score each area it is vital to be honest so that you can identify areas for change and set goals to improve your life.
                    </p>
                    <p className="textnots-text">
                      Complete the life assessment. (Be totally honest with yourself on where you truly are.)
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="life-wheel-tabel">
                        <table className="table table-bordernone">
                          <thead>
                            <tr>
                              <th>Life Factor</th>
                              <th>Rating</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questions.map((question) => (
                              <tr key={question.id}>
                                <td><b>{question.question}</b></td>
                                <td className="d-flex justify-content-around">
                                  {[...Array(10)].map((_, index) => (
                                    <React.Fragment key={index + 1}>
                                      <input
                                        type="radio"
                                        className={`radio_animated radio-${index < 3 ? 'primary' : index <= 6 ? 'secondary' : index <= 7 ? 'success' : 'success'}`}
                                        name={`rating_${question.id}`}
                                        value={index + 1}
                                        checked={formData[`rating_${question.id}`] === String(index + 1)}
                                        onChange={handleChange}
                                      />
                                      <span className="family-rating-lifeassesment">{index + 1}</span>
                                    </React.Fragment>
                                  ))}

                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Save button removed */}
              </form>
            </div>
          </div>
        </div>
      </div>


      <AltemateYou />
    </>
  );
}

export default LifeAssessment;
