import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import API, { isSubscribedAccountant } from '../../../utils.js';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from "axios";


const LifeWheelteb = () => {
    const navigate = useNavigate();
    const uid = localStorage.getItem('user_id') || '';
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
return;
        }
    }, [navigate]);

    useEffect(() => {
        const storedFormData = localStorage.getItem('lifeWheelFormData');
        if (storedFormData) {
            setFormData(JSON.parse(storedFormData));
        }
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('main_category_id', '1');
        formDataToSend.append('sub_category_id', '1');

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getquestionbycatidsubcatidmyreality}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[`rating_${question.id}`] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);

        // Save the updated form data to localStorage
        localStorage.setItem('lifeWheelFormData', JSON.stringify(updatedFormData));

        // Trigger the API call for updating the answer
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, updatedFormData[`rating_${question.id}`] || '');
        });
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addupdateanswersmyreality}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };
    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[`rating_${question.id}`] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addupdateanswersmyreality}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // toast.success(response.data.message);
                fetchQuestions();
                localStorage.setItem('lifeWheelFormData', JSON.stringify(formData));
            } else {
                // toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>My Life Wheel</h5>
                                </div>
                            </div>
                        </div>
                        <form className="form theme-form">
                            <div className="card-body">
                                <div className="textnots-container-persomality mb-3">
                                    <strong>Step 1: Assess Your Life </strong><br></br>
                                    <p className="textnots-text">Rate how you are doing in each segment on a scale of 0-10 (0 = lowest, 10 = ideal state).</p>
                                    <strong>Taking a Helicopter View of Your Life</strong>
                                    <p className="textnots-text">
                                        To be truly fulfilled, one needs to lead a balanced life. When life is busy, or all your energy is focused on a special project, you can find yourself ‘off balance’, not paying enough attention to important areas of your life. By regularly taking a ‘helicopter view’ of your life, you can bring things back into balance.
                                    </p>
                                    <p className="textnots-text">
                                        The Wheel of Life helps you quickly and graphically identify the areas in your life to which you want to devote more energy. The eight sections of the wheel represent the key areas of your life. Ideally, these areas should be in balance just like a wheel.
                                    </p>
                                </div>
                                <div className="circle-container circl1">
                                    {questions.map((question, index) => {
                                        const isReversed = ['Social Life', 'Family', 'Love Life'].includes(question.question); // Check if the question is one of the reversed ones
                                        const radioValues = isReversed ? [...Array(10)].reverse() : [...Array(10)]; // Reverse the array if necessary

                                        return (
                                            <div
                                                key={question.id}
                                                className="section section-life-wheel"
                                                style={{ '--rotate': `${index * 45}deg` }}
                                            >
                                                <div className="radio-group_life_wheel radio-group-life">
                                                    {radioValues.map((_, i) => (
                                                        <React.Fragment key={i + 1}>
                                                            <div className="life-wheel">
                                                                <input
                                                                    type="radio"
                                                                    name={`rating_${question.id}`}
                                                                    value={i + 1}
                                                                    checked={formData[`rating_${question.id}`] === String(i + 1)}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="family-rating-lifeweel">{i + 1}</span>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <div className="question">
                                                    {question.question}
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>

                            </div>
                            {/* <div className="card-footer">
                                <Button onClick={handleSubmit} variant="success" className="btn main-buttons me-1 float-end">Save</Button>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LifeWheelteb;