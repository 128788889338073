import React from "react";
import Default from "../components/dashboard/defaultCompo/default";
import KeyRelationship from "../components/personal-growth/keyRelationship";


// users
// import UserProfile from "../components/users/userProfile";
import UserEdit from "../components/users/userEdit";
import UserCards from "../components/users/user-cards";

// personal-growth
import Task from "../components/personal-growth/taskApp";
import DailyGratitude from "../components/personal-growth/daily-gratitude";
import DailyAffirmation from "../components/personal-growth/daily-affirmation";

// personalos
import LifeInGeneral from "../components/personalos/lifeIngeneral";
import Physicalhealth from "../components/personalos/physicalhealth";
import MentalHealth from "../components/personalos/mentalhealth";
import RomanticLife from "../components/personalos/romanticlife";
import PersonalFinances from "../components/personalos/personalfinances";
import Family from "../components/personalos/family";
import SocialLife from "../components/personalos/sociallife";
import Career from "../components/personalos/career";
import Business from "../components/personalos/business";
import PersonalPurposeMission from "../components/personalos/personalpurposemission";
import PersonalGrowth from "../components/personalos/personalgrowth";
import HomeEnvironment from "../components/personalos/homeenvironment";
import FunRecreation from "../components/personalos/funrecreation";
import PassionsHobbies from "../components/personalos/Passionsbobbies";
import Travel from "../components/personalos/travel";
import Spiritual from "../components/personalos/spiritual";

// life360
import MyCoreValues from "../components/life360/mycorevalues";
import MyVisiontatement from "../components/life360/myvisionstatement";
import Mypurose from "../components/life360/myPurposeAndPassion";

import MyMission from "../components/life360/mymission";
import SelfReflection from "../components/life360/selfreflection";
import ThoseIWantToServe from "../components/life360/thoseiwanttoserve";
import MyLifeMotto from "../components/life360/mylifemotto";

import MyIdentity from "../components/life360/myidentity";
import PersonalityProfile from "../components/life360/personalityprofile";
import MyPassion from "../components/life360/mypassion";


// fuchar
import RelationshipWins from "../components/myfuture/RelationshipWins";
import Obstacles from "../components/myfuture/Obstacles";
import RoleModel from "../components/myfuture/RoleModel";
import Bigdata from "../components/myfuture/Bigdata";

import LifeGoal from "../components/myfuture/LifeGoal";
import VisionBoard from "../components/myfuture/visionBoard";
import BucketList from "../components/myfuture/bucketList";
import PerFectDay from "../components/myfuture/perfectDay";
import MyLifeMenifesto from "../components/myfuture/lifeManifesto";
import Life360Progress from "../components/my-reports/life360progress";
import Life360Id from "../components/my-reports/life360id";
import Life360Bluprint from "../components/my-reports/life360bluprint";
import AltemateYou from "../components/my-reality/altimate-you";
import LifeAssessment from "../components/my-reality/life-assessment";
import LifeWheel from "../components/my-reality/life-wheel";
import MyPesonalFinance from "../components/my-reality/personal-finance";
import DefineSuccess from "../components/my-belief/define-success";
import BeliefSystem from "../components/my-belief/belief-system";
import MyLegacy from "../components/myfuture/MyLegacy";
import LifePlan from "../components/dashboard/life-plan";
import HabitTracker from "../components/personal-growth/habitTracker";
import ThingsILovehate from "../components/life360/thing-i -love-hate";
import PersonalSWOT from "../components/life360/personalswot";
import AnnualOutcomes from "../components/personal-growth/annualOutcomes";
import PersonalBranding from "../components/life360/personal-branding";
import LifelineMilestones from "../components/my-reality/lifelinemilestones";
import Personalbio from "../components/life360/personalbio";
import IndexNotes from "../components/my-notes/myNotes";

export const routes = [

  { path: '/',  Component: <Default /> },
  { path: '/dashboard/default', Component: <Default /> },
  { path: '/home/Life-plan', Component: <LifePlan /> },
  // {/ personal-growth /}
  { path: '/personal-growth/task', Component: <Task /> },
  { path: '/personal-growth/daily-gratitude', Component: <DailyGratitude /> },
  { path: '/personal-growth/daily-affirmation', Component: <DailyAffirmation /> },
  { path: '/personal-growth/key-relationship', Component: <KeyRelationship /> },
  { path: '/personal-growth/habit-tracker', Component: <HabitTracker /> },
  { path: '/personal-growth/annual-goals-outcomes', Component: <AnnualOutcomes /> },

  //myfuture
  { path: 'future/RelationshipWins', Component: <RelationshipWins /> },
  { path: 'future/RoleModel', Component: <RoleModel /> },
  { path: 'future/Obstacles', Component: <Obstacles /> },
  { path: 'future/Bigdata', Component: <Bigdata /> },
  { path: 'future/MyLegacy', Component: <MyLegacy /> },

  // {/ Personal OS /}
  { path: '/mylifeplan/lifeingeneral', Component: <LifeInGeneral /> },
  { path: '/mylifeplan/physicalhealth', Component: <Physicalhealth /> },
  { path: '/mylifeplan/mentalhealth', Component: <MentalHealth /> },
  { path: '/mylifeplan/romanticlife', Component: <RomanticLife /> },
  { path: '/mylifeplan/personalfinances', Component: <PersonalFinances /> },
  { path: '/mylifeplan/family', Component: <Family /> },
  { path: '/mylifeplan/sociallife', Component: <SocialLife /> },
  { path: '/mylifeplan/career', Component: <Career /> },
  { path: '/mylifeplan/business', Component: <Business /> },
  { path: '/mylifeplan/personal-purpose-mission', Component: <PersonalPurposeMission /> },
  { path: '/mylifeplan/personalgrowth', Component: <PersonalGrowth /> },
  { path: '/mylifeplan/homeenvironment', Component: <HomeEnvironment /> },
  { path: '/mylifeplan/funrecreation', Component: <FunRecreation /> },
  { path: '/mylifeplan/Passionsbobbies', Component: <PassionsHobbies /> },
  { path: '/mylifeplan/travel', Component: <Travel /> },
  { path: '/mylifeplan/spiritual', Component: <Spiritual /> },

  // {/ life360 /}
  { path: '/life360/mycorevalues', Component: <MyCoreValues /> },
  { path: '/life360/myvisionstatement', Component: <MyVisiontatement /> },
  { path: '/life360/mypurpose', Component: <Mypurose /> },

  { path: '/life360/mymission', Component: <MyMission /> },
  { path: '/life360/mypassion', Component: <MyPassion /> },
  { path: '/life360/those-i-want-to-serve', Component: <ThoseIWantToServe /> },
  { path: '/life360/mylifemotto', Component: <MyLifeMotto /> },
  { path: '/life360/selfreflection', Component: <SelfReflection /> },
  { path: '/life360/myidentity', Component: <MyIdentity /> },
  { path: '/life360/mypersonality', Component: <PersonalityProfile /> },
  { path: '/life360/things-i-love-hate', Component: <ThingsILovehate /> },
  { path: '/life360/personal-swot', Component: <PersonalSWOT /> },
  { path: '/life360/personal-branding', Component: <PersonalBranding /> },
  { path: '/future/Personalbio', Component: <Personalbio /> },

  // {/ Users /}
  // { path: '/users/userProfile', Component: <UserProfile /> },
  { path: '/users/userEdit', Component: <UserEdit /> },
  { path: '/users/userCards', Component: <UserCards /> },

  //myfuture
  { path: 'future/RelationshipWins', Component: <RelationshipWins /> },
  { path: 'future/RoleModel', Component: <RoleModel /> },
  { path: 'future/Obstacles', Component: <Obstacles /> },
  { path: 'future/Bigdata', Component: <Bigdata /> },
  { path: 'future/life-menifesto', Component: <MyLifeMenifesto /> },
  { path: 'future/life-goal', Component: <LifeGoal /> },
  { path: 'future/vision-board', Component: <VisionBoard /> },
  { path: 'future/bucket-list', Component: <BucketList /> },
  { path: 'future/perfect-day', Component: <PerFectDay /> },

  //reports
  { path: '/my-reports/LifePlanProgress', Component: <Life360Progress /> },
  { path: '/my-reports/life-360-id', Component: <Life360Id /> },
  { path: '/my-reports/this-year-trategic-plan', Component: <Life360Bluprint /> },

  //my-notes
  { path: '/notes', Component: <IndexNotes /> },

  //My Reality
  { path: '/my-reality/altimate-you', Component: <AltemateYou /> },
  { path: '/my-reality/life-assessment', Component: <LifeAssessment /> },
  { path: '/my-reality/life-wheel', Component: <LifeWheel /> },
  { path: '/my-reality/life-line', Component: <LifelineMilestones /> },
  { path: '/my-reality/my-personal-finance', Component: <MyPesonalFinance /> },

  //my-belief
  { path: '/my-belief/define-success', Component: <DefineSuccess /> },
  { path: '/my-belief/belief-system', Component: <BeliefSystem /> },

  //subscription


];
