import React, { useEffect, Fragment, useState } from "react";
import { FaBullseye, FaTasks } from 'react-icons/fa';
import { Line } from "react-chartjs-2";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import MyLifeTheme from "./myLifeTheme";
import MyTaskDefault from "./myTasks";
import UpcomingAction from "./upcomingAction";
import PriorityTask from "./priorityTask";
import LifePlan from "./lifePlan";
import DailyAffirmation from "./dailyAfformation";
import CurrentLifeRatting from "./currentLifeRatting";
import DashBoardHabit from "./dashboardHeabit";
import EventCharts from "./eventCharts";
import configDB from "../../../data/customizer/config";
import API from "../../../utils";
import BarChart from "./lifePlanBarChart";
import Life360Id from "../../my-reports/life360id";
import TrackLifeGoals from "./lifeGoals";
import DashboardReport from "./dashboardReport";
import BarChartdashboard from "./lifeplanprogress";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const primary = localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const Default = () => {
  const uid = localStorage.getItem('user_id') || '';
  const currentYear = new Date().getFullYear();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [chartData, setChartData] = useState({
    labels: ["Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        data: [],
        lineTension: 0.5,
        borderColor: primary,
      },
    ],
  });

  // useEffect(() => {
  //   fetchData(selectedYear);
  // }, [selectedYear]);

  const fetchData = async (year) => {
    setSelectedYear(year);
    const formDataToSend = new FormData();
    formDataToSend.append('selected_year', year);
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_data_for_chart}`,
        formDataToSend,
        {
          headers: {
            Authorization: "FFxx21$$21life360AAA212TRRR",
          },
        }
      );

      const data = response.data.data;

      if (response.data && response.data.status === "success") {
        const chartData = {
          labels: ["Q1", "Q2", "Q3", "Q4"],
          datasets: [
            {
              data: data.map(item => item.count),
              lineTension: 0.5,
              borderColor: primary,
            },
          ],
        };

        setChartData(chartData);
      } else {
        const errorMessage = response.data ? response.data.message : "Unknown error";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Fetch checked days error", error);
      toast.error("Failed to fetch checked days.");
    }
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-xl-12 xl-100">
            <div className="row">
              <EventCharts />
            </div>
          </div> */}
          <div className="col-sm-12">
          <BarChartdashboard/>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <MyLifeTheme />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <MyTaskDefault />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <UpcomingAction />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <PriorityTask />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <DailyAffirmation />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
          
          
              {/* {chartData.datasets[0].data.length > 0 ? (
              <div className="card-body">
                <div className="smooth-chart flot-chart-container">
                
                    <Line data={chartData} options={gradientChartOptions} height="360" />
                 
                </div>
              </div>
               ) : (
                <p className="text-center mt-4">No data to display</p>
              )} */}

              <TrackLifeGoals/>
            
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <DashBoardHabit />
          </div>
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
            <CurrentLifeRatting />
          </div> */}
       

          <div className="col-lg-12 col-md-12 col-sm-12">
            <DashboardReport />
          </div>

          
        </div>
      </div>
      {isDeleteModalOpen && (
        <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete {selectedItem && selectedItem.thoseiwanttoserve}?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
            <button className="btn btn-danger me-1" onClick={toggleDeleteModal}>Delete</button>
          </Modal.Footer>
        </Modal>
      )}

    </Fragment>
  );
};

export default Default;

export var gradientChartOptions = {
  maintainAspectRatio: false,
  height: 360,
  width: 500,
  bezierCurve: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'category',
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
      display: true,
    },
    y: {
      type: 'linear',
      display: true,
    },
  },
};
