import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";
import API, { isSubscribedAccountant } from "../../utils";
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";

const DailyAffirmation = () => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    affirmation: []
  });
  const [AffirmationEntries, setAffirmationEntries] = useState([]);
  const [updatedTask, setUpdatedTask] = useState(null);

  useEffect(() => {
    fetchData();

    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
      return;
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.getaffirmations}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const affirmations = response.data.tasks[0]?.affirmation || [];
        setAffirmationEntries(response.data.tasks);

        // Set formData with fetched affirmations
        setFormData({
          affirmation: affirmations
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch affirmation error', error);
      toast.error("Failed to fetch affirmations.");
    }
  };

  const debouncedUpdateAffirmation = useCallback(
    debounce(async (affirmation) => {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      affirmation.forEach((text, index) => {
        formDataToSend.append(`affirmation[${index}]`, text || ''); // Ensure no null or undefined values
      });

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addaffirmations}`, formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });

        if (response.data.status === "success") {
          toast.success(response.data.message);
          fetchData(); // Refetch data without resetting fields
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Failed to add affirmation.");
      }
    }, 1000),
    [uid]
  );

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateAffirmation(updatedTask);
    }
  }, [updatedTask, debouncedUpdateAffirmation]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedAffirmation = [...formData.affirmation];
    updatedAffirmation[index] = value || ''; // Handle null or undefined values
    setFormData({ affirmation: updatedAffirmation });
    setUpdatedTask(updatedAffirmation); // Trigger debounced update
  };

  const addMoreFields = () => {
    setFormData(prev => ({
      affirmation: [...prev.affirmation, ""] // Add one more empty field
    }));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>My Key Affirmations</h5>
            </div>

            <div className="card-body">
              <div className="dayllyaffimation-container mb-3">
                <p className="textnots-text">
                  <strong>My Key Affirmations</strong>
                </p>
                <p className="textnots-text">
                  The practice of affirming, declaring, committing to what is good and being grateful for what we have provides us with the recognition of how privileged we really are and how much we are given and how much we can create.
                </p>

                <p className="textnots-text"><strong>Examples of Affirmations:</strong></p>
                <p className="textnots-text">“My life is fun and rewarding.”</p>
                <p className="textnots-text">“I am smart and capable of accomplishing anything.”</p>
                <p className="textnots-text">“I am grateful for everything I have.”</p>
                <p className="textnots-text">“I attract positive, kind-hearted people.”</p>
              </div>
              <div>
                <div className="my-4">
                  <h6>Add Daily Affirmation</h6>
                  <form className="theme-form">
                    <div className="row">
                      {formData.affirmation.map((affirmation, index) => (
                        <div className="col-lg-4 col-md-12" key={index}>
                          <div className="form-group">
                            <label htmlFor={`affirmation${index}`}>Affirmation Statement</label>
                            <input
                              id={`affirmation${index}`}
                              placeholder={`Enter Affirmation Statement`}
                              value={affirmation || ''}
                              onChange={(e) => handleInputChange(e, index)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))}
                      <div className="col-lg-12 text-end">
                        <Button type="button" className="text-end" onClick={addMoreFields}>Add An Affirmation Statement</Button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="my-4">
                  <h6>Existing Affirmations</h6>
                  <ul className="list-group">
                    {AffirmationEntries.map((entry) => (
                      <React.Fragment key={entry.id}>
                        {entry.affirmation.map((affirmation, index) => (
                          <li key={`${entry.id}-${index}`} className="list-group-item">
                            <strong>Affirmation Statement:</strong> {affirmation || 'No affirmation'}
                          </li>
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyAffirmation;
