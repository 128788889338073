import React, { useEffect, useState, useCallback } from "react";
import { ProgressBar } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash';

const MyPassion = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
            return;
        }
    }, [navigate]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "5");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            formDataToSend.append('question_id[]', questionId);
            formDataToSend.append('answer[]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    // toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };

    const handleSubmitmotivators = (e) => {
        e.preventDefault();
        handleSubmit(e);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>My Passion</h5>
                            </div>
                            <form onSubmit={handleSubmitmotivators}>
                                <div className="card-body m-3">
                                    <div className="textnots-container-valueandpurpose mb-3">
                                        <p className="textnots-text">
                                            <b>Passions:</b> Passions make work play. <b> Ask yourself: </b> What do I love to do? What can I not imagine living without? What makes a day or an experience more complete to me?
                                        </p>
                                        <p className="textnots-text">
                                            <b>Examples:</b> Singing, playing instruments, knitting, gardening, travelling, reading, writing, shopping, collecting different items (like coins, stamps etc.), sports, coding, painting, drawing, fishing, scrap booking, camping, photography, running, skiing, surfing, hiking, etc.
                                        </p>
                                        <p className="textnots-text">
                                            Passion may seem like an odd word choice when paired with career but rest assured that one of the most important elements of personal happiness is being passionate about your career. You do not want to be one of those people who live for the weekends and dread Sunday evenings. Life is too short to not love the work you do. Take a few moments to answer the questions below. For each answer try to come up with 3-5 responses.
                                        </p>
                                    </div>

                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                                            <textarea
                                                className="form-control"
                                                name={question.id}
                                                value={formData[question.id]}
                                                onChange={handleChange}
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="card-footer text-end">
                                    <button type="submit" className="btn btn-success mx-1">Save</button>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyPassion;
