import { Modal } from "react-bootstrap";

const GratitudeEntrymodal = ({ isModalOpen, toggleModal, handleSubmit, formData, handleInputChange, isSubmitDisabled }) => {
    return (
        <Modal show={isModalOpen} onHide={toggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>Daily Gratitude</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You haven't added your daily gratitude for today. Please input your gratitude to continue:</p>
                <form className="theme-form my-3" onSubmit={handleSubmit}>
                    <div className="row">
                        {/* Render text areas for gratitude inputs */}
                        {formData.gratitude.map((gratitude, index) => (
                            <div className="col-lg-12 col-md-12 mb-2" key={index}>
                                <textarea
                                    id={`gratitude${index + 1}`}
                                    placeholder={`Enter Gratitude ${index + 1}`}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`form-control`}
                                    value={formData.gratitude[index]}
                                />
                            </div>
                        ))}
                        <div className="d-flex justify-content-end align-content-center">
                            <button className="btn btn-secondary" type="submit" disabled={isSubmitDisabled}>Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default GratitudeEntrymodal;
