import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Purpose from "./myMissiontabs/purpose";
import ValueAndPurpose from "./myMissiontabs/valueAndPurpose";
import { useNavigate } from "react-router-dom";

const MyMission = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("PURPOSE");
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "My Mission");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Container fluid={true}>
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabChange}
                id="mission-statement-tabs"
                className="border-tab nav-primary mb-3"
                justify
            >
                <Tab eventKey="PURPOSE" title="Purpose">
                    <Purpose activeTab={activeTab} />
                </Tab>
                <Tab eventKey="Family" title="Values & Purpose">
                    <ValueAndPurpose activeTab={activeTab} />
                </Tab>
            </Tabs>
        </Container>
    );
};

export default MyMission;
