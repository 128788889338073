import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Container } from "react-bootstrap";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const PerfectDay = () => {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const uid = localStorage.getItem('user_id');
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
return;
        }
    }, [navigate]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "6");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question) => {
            formDataToSend.append(`question_id[]`, question.id);
            formDataToSend.append(`answer[]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[]', questionId);
            formDataToSend.append('answer[]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status !== 'success') {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>My Perfect Day</h5>
                    </div>
                    <div className="card-body m-3">
                        <div className="myvision mb-3">
                            <strong>Questions to Get You Going:</strong>
                            <p className="textnots-text">
                                It’s easy to get stuck and sit there staring at a blank piece of paper. If this is you, try interviewing yourself. Start asking yourself questions on what you do at different points of your day. Here are a few questions to get you going:
                            </p>
                        </div>

                        <form>
                        {questions.map((question, index) => (
    <div className="form-group row mb-2 p-0" key={question.id}>
        <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
        {([0, 2, 4, 11, 13].includes(index)) ? (
            <input
                className="form-control"
                type="text"
                name={question.id}
                value={formData[question.id]}
                onChange={handleChange}
            />
        ) : (
            <textarea
                className="form-control"
                name={question.id}
                value={formData[question.id]}
                onChange={handleChange}
                rows="4"
            ></textarea>
        )}
    </div>
))}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerfectDay;
