import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API, { isSubscribedAccountant } from '../../../utils';

const TestresultBehaviorstyle = ({ activeTab }) => {
    const navigate = useNavigate();
    const [visionStatements, setVisionStatements] = useState({});
    const [lifeAreas, setLifeAreas] = useState([]);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const uid = localStorage.getItem("user_id");

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_question_to_disc_test_behaviour_test_result}`,
                null, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setLifeAreas(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch role model data error', error);
            toast.error("Failed to fetch role model data.");
        }
    };

    const fetchAnswers = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_to_disc_test_behaviour_test_result}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const visionData = response.data.data.reduce((acc, item) => {
                    acc[item.question_id] = {
                        rating: item.rating,
                        insert_note: item.insert_note,
                    };
                    return acc;
                }, {});
                setVisionStatements(visionData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch answers data error', error);
            toast.error("Failed to fetch answers data.");
        }
    };

    const handleChange = (e, key, field) => {
        const { value } = e.target;
        setVisionStatements({
            ...visionStatements,
            [key]: {
                ...visionStatements[key],
                [field]: value,
            },
        });
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newTimer = setTimeout(() => {
            handleSubmit(key);
        }, 2000); // Adjust the delay as needed

        setDebounceTimer(newTimer);
    };

    const handleSubmit = useCallback(async (key) => {
        const visionStatement = visionStatements[key];
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', key);
        formDataToSend.append('rating', visionStatement?.rating);
        formDataToSend.append('insert_note', visionStatement?.insert_note);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_to_disc_test_behaviour_test_result}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // Optionally fetch updated answers here
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Add vision statement error', error);
            toast.error("Failed to add vision statement.");
        }
    }, [visionStatements, uid]);

    useEffect(() => {
        fetchData();
        fetchAnswers(); // Fetch answers data on component mount
    }, []);

    return (
        <div className="behavior-style-container">
            <h3>Behavior Style</h3>
            {lifeAreas.map((area, index) => (
                <div key={area.id} className="behavior-area">
                    <label><b>{area.question}</b></label>
                    {index === 0 ? ( // For the first index
                        <input
                            type="text"
                            className="form-control vision-input-field"
                            name={area.id}
                            placeholder="Insert Here..."
                            value={visionStatements[area.id]?.insert_note || ''}
                            onChange={(e) => handleChange(e, area.id, 'insert_note')}
                        />
                    ) : index === 1 ? ( // For the second index
                        <textarea
                            className="form-control "
                            name={area.id}
                            placeholder="Insert Here..."
                            value={visionStatements[area.id]?.insert_note || ''}
                            onChange={(e) => handleChange(e, area.id, 'insert_note')}
                        />
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default TestresultBehaviorstyle;
