import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import API, { isSubscribedAccountant } from '../../../utils.js';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';

const Reflection = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
return;
        }
    }, []);

    const uid = localStorage.getItem('user_id') || '';
    const [errors, setErrors] = useState({});
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem('formData');
        return savedData ? JSON.parse(savedData) : {};
    });

    // const checkboxOptions = [
    //     { id: 'option1', label: "Do One Thing You've Always Wanted To" },
    //     { id: 'option2', label: "Take on a Fitness Challenge" },
    //     { id: 'option3', label: "Change Up Your Routine" },
    //     { id: 'option4', label: "Expand Your Professional Skill Set" },
    //     { id: 'option5', label: "Choose a Fear, and Face It" },
    //     { id: 'option6', label: "Travel Somewhere New" },
    // ];

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('main_category_id', '1');
        formDataToSend.append('sub_category_id', '2');

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getquestionbycatidsubcatidmyreality}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                // toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (updatedFormData) => {
            const formDataToSend = new FormData();
            questions.forEach((question, index) => {
                formDataToSend.append(`question_id[${index + 1}]`, question.id);
                formDataToSend.append(`answer[${index + 1}]`, updatedFormData[question.id] || '');
            });

            formDataToSend.append('user_id', uid);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.addupdateanswersmyreality}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    fetchQuestions(); 
                } else {
                    // toast.error(response.data.message || 'Failed to update answers');
                }
            } catch (error) {
                console.error('Error updating answers:', error);
                toast.error('Failed to update answers. Please try again later.');
            }
        }, 2000), 
        [questions, uid]
    );

    const handleChange = (e, questionId) => {
        const { name, value, type, checked } = e.target;
    
        setFormData((prevData) => {
            let updatedFormData;
    
            if (type === 'checkbox') {
                const questionAnswers = prevData[questionId] || [];  
                if (checked) {
                    updatedFormData = {
                        ...prevData,
                        [questionId]: [...questionAnswers, name],
                    };
                } else {
                    updatedFormData = {
                        ...prevData,
                        [questionId]: questionAnswers.filter((answer) => answer !== name),
                    };
                }
            } else {
                updatedFormData = {
                    ...prevData,
                    [name]: value,
                };
            }
    
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
    
            // Call debounced function
            debouncedUpdateAnswer(updatedFormData);
    
            return updatedFormData;  // Ensure the updated data is returned to setFormData
        });
    };
    

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Reflection</h5>
                        </div>
                        <form className="form theme-form" noValidate>
                            <div className="card-body mx-3">
                                <div className="textnots-container-persomality mb-3">
                                    <p className="textnots-text">
                                        Would you like to improve your life or identify areas of your life that you can improve to increase your happiness? Onetool that we regularly use with our clients who are looking to increase their personal happiness and success is the LifeAssessment.  This is a very simple yet powerful tool to help you identify the areas of your life that you have neglected
                                        and need to prioritize more in future.  It takes less than 10 minutes to complete.

                                    </p>
                                    <p className="textnots-text">
                                        Give yourself a score of 1 to 10 depending on how satisfied you currently are in relation to each area on the assessmentwhere 1 is pretty much as unhappy as you can be whereas 10 is the perfect satisfaction score.  As you score each area itis vital to be honest so that you can identify areas for change and set goals to improve your life.
                                    </p>
                                    <p className="textnots-text">
                                        Complete the life assessment.(Be totally honest with yourself on where you truly are.)
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {questions != null && questions != undefined ? questions.map((question, index) => (
                                            <div className="form-group row mb-2" key={question.id}>
                                                <label className="col-sm-12 col-form-label mb-2"><b>{question.question}</b></label>
                                                    <textarea
                                                        className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                                                        name={question.id}
                                                        value={formData[question.id] || ''}
                                                        onChange={(e) => handleChange(e, question.id)}
                                                        rows="4"
                                                    ></textarea>
                                              
                                                {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                                            </div>
                                        ))
                                            : (
                                                <p>No Reflection available.</p>
                                            )}
                                    </div>
                                </div>
                            </div>

                       
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reflection;
