import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.scss";
import "../src/i18n";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/index.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";
import MainRoutes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = '52287124339-ibcfdq2tuni4d55li1eu05bnn0dhk61h.apps.googleusercontent.com'; // Replace with your actual client ID

const Root = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="App">
        <Provider store={store}>
          <MainRoutes />
        </Provider>
      </div>
    </GoogleOAuthProvider>

  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.unregister();
