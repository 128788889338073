import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container, Nav, NavItem, Tab, TabContent, TabPane, Tabs } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CoreBeliefBreakdown from "./beliefsystem/corebeliefbreakdown";
import EarlyExperiencesWorksheet from "./beliefsystem/earlyexperiences";

const BeliefSystem = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('Core Belief System');
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_name', "Core Belief System");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getsubcategoryformmybelief}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch tabs.");
        }
    };

    const handleSelect = (event) => {
        setActiveTab(event.target.value); 
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };
    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };
    

    return (
        <>
            <Container fluid>
          
              
                    <Tabs
                        onSelect={handleTabChange}
                        defaultActiveKey="Core Belief System"
                        id="justify-tab-example"
                        className="border-tab nav-primary mb-3"
                        justify
                    >
                        {tabs.map(tab => (
                            <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                                {getTabContent(tab.sub_category)}
                            </Tab>
                        ))}
                    </Tabs>
                

            </Container>
        </>
    );
};

const getTabContent = (tabName) => {
    switch (tabName) {
        case 'Core Belief System':
            return <CoreBeliefBreakdown />;
        case 'Origins Of Core Beliefs':
            return <EarlyExperiencesWorksheet />;
        default:
            return null;
    }
};

export default BeliefSystem;
