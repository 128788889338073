
const API = {
  // PROXY_URL: "http://localhost:8080/",
  // BASE_URL: "http://reactapi.fableadtechnolabs.in/api/api",
  AUTHORIZATION_TOKEN: "FFxx21$$21life360AAA212TRRR",

  PROXY_URL: `${process.env.REACT_APP_PROXY_URL}`,
  BASE_URL: `${process.env.REACT_APP_BASE_URL}`,
  // AUTHORIZATION_TOKEN:`${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,

  ENDPOINTS: {

    get_top5new_data:"/get_top5new_data",
    signup: "/signup_subscription",
    login: "/login",
    googlelogin: "/googleLogin",
    facebooklogin: "/facebookLogin",
    forgotPassword: "/forgotPassword",
    changepassword: "/changepassword",
    getuserdetails: "/get_user_details",
    updateprofile: "/update_profile",

    GetDetailsAbilityPartner: "/get_details",
    CreateAccountAbilityPartner: "/create_account",
    DeleteAbilityPartner: "/delete",
    sendPdf:'/sendPdf',
    
    //life plan
    all_life_area: "/all_life_area",
    vertialrow: "/all_life_verticalq",
    editAnswer: "/add_life_selected_data",
    getAnswer: "/get_life_selected_data",
    life_area_is_check:"/life_area_is_check",

    //add life theme
    addLifeTheme: "/add_life_theme",
    getLifeThemes: "/all_life_theme",

    //tasks
    viewTask: "/task",
    update: "/tasks",
    addTask: "/tasks",
    complete_hide:'/complete_hide',
    edittasks: "/tasks",
    allTask: "/tasks",
    deleteTask: "/deleteTask",

    //outcomes
    gratitudes: "/gratitudes",
    allgratitudes: "/gratitudes",
    viewgratitude: "/gratitude",
    editgratitudes: "/gratitudes",
    deleteGratitudes: "/deleteGratitudes",
    gratitude_notification_mail:'/gratitude_notification_mail',
    
    //daily outcomes 
    add_outcomes: "/add_outcomes",
    update_outcomes: "/update_outcomes",
    delete_outcomes: "/delete_outcomes",
    all_outcomes: "/all_outcomes",
    get_outcomes_details: "/get_outcomes_details",

    getaffirmations: "/affirmations",
    addaffirmations: "/affirmations",
    affirmationsedit: "/affirmations",
    deleteaffirmations: "/deleteAffirmation",
    viwesaffirmations: "/affirmation",

    //KeyRelationship
    getkeyrelationship: "/keyRelationship",
    addKeyRelationship: "/addKeyRelationship",
    updateKeyRelationship: "/updateKeyRelationship",
    deleteKeyRelationship: "/deleteKeyRelationship",
    showKeyrelationship: "/showKeyRelationship",

    //Personalos
    addpersonalos: "/add_personalos",
    getquestionbycategoty: "/get_question_bycategoty",
    getonlyquestions: "/get_only_questions",
    

    //habit tracker
    addHabit: "/addHabit",
    myHabits: "/myHabits",
    habictracker: "/trackHabit",
    getHabitDate: "/habit-tracking-data",
    deleteHabit: "/deleteHabit",
    habit_reminder_notification_mail:'/habit_reminder_notification_mail',
    
    //priorities_tasks

    upcomingAction: "/priorities_tasks",
    latestTask: "/get_letest_task",



    //core value 

    addCoreValue: "/add_corevalue",
    getCoreValue: "/get_core_value",
    updateTopTen: "/update_core_status",
    topTenCoreValue: "/get_top10_core",
    topFive: "/update_core_top5status",
    getFiveCoreValur: "/get_top5_core",
    updateCoreValue: "/update_core_value",
    deleteCoreValue: "/Delete_core_value",
    getCoreValueDetails: "/view_core_value",




    //life 360
    //vision statement
    //Those I Want To Serve
    add_those_serve: "/add_those_serve",
    all_those_serve: "/all_those_serve",
    update_those_serve: "/update_those_serve",
    delete_those_serve: "/delete_those_serve",


    //My Promises To Myself

    add_promise: "/add_promise",
    all_promise: "/all_promise",
    update_promise: "/update_promise",
    delete_promise: "/delete_promise",
    //vision statement


    getSubCategory: "/get_sub_category",
    getLifeQuestion: "/get_question_by_catid_subcatid",
    updateAnswer: "/add_update_answers",

    //Life Moto
    lifemotoadd: "/add_life_motto",
    lifemotoall: "/all_life_motto",
    lifemotodelete: "/delte_life_motto",
    lifemotoupdate: "/update_life_motto",

    //mynotes

    get_question_from_notes: "/get_question_from_notes",
    get_notes_data_by_uid_qid: "/get_notes_data_by_uid_qid",
    add_update_answers_from_notes: "/add_update_answers_from_notes",
    add_update_url_links: "/add_update_url_links",
    add_update_documents: "/add_update_documents",

    //i love/hate
    add_love_hate: "/add_update_love_hate",
   
    delete_love_hate: "/delete_love_hate",

    // life goal
    add_life_goal: "/add_life_goal",
    get_life_goalsbyuser: "/get_life_goals_by_user",
    updatelifegoal: "/update_life_goal",
    deletelifegoal: "/delete_life_goal",


    // selfReflection
    getselfReflection: "/life-360-selfReflections",
    addselfReflection: "/life-360-selfReflection-store",
    deleteSelfReflection: "/life-360-selfReflection-delete",
    updetselfReflection: "/life-360-selfReflection-update",

    // accomplishment
    life360accomplishmentstore: '/life-360-accomplishment-store',
    life360accomplishmentget: "/life-360-accomplishments",
    life360accomplishmentupdeat: "/life-360-accomplishment-update",
    life360accomplishmentdelete: "/life-360-accomplishment-delete",

    life360reflectionstore: '/life-360-reflection-store',
    life360reflectionget: '/life-360-reflections',
    life360reflectionupdate: '/life-360-reflection-update',
    life360reflectiondelete: '/life-360-reflection-delete',

    get_user_tasks_by_type: "/get_user_tasks_by_type",

    //life 360 drivers
    addDrivers: "/life-360-personality-driver-store",
    showDrivers: "/life-360-personality-drivers",
    updateCheckbox: "/life-360-personality-driver-update",
    deleteCheckbox: "/life-360-personality-driver-delete",


    //my future
    futureSUb: "/get_sub_category_from_MyFuture",
    getQuestions: "/get_question_by_catid_subcatid_from_MyFuture",
    addAnswer: "/add_update_answers_from_MyFuture",


    //role_model

    addrolemodel: "/add_role_model",
    getrolemodel: "/get_role_model",
    deleterolemodel: "/delete_role_model",
    updetrolemodel: "/update_role_model",

    // mybelief
    getsubcategoryformmybelief: "/get_subcategory_form_mybelief",
    getquestionbymainandsub: "/get_question_by_mainandsub",
    addupdateanswermybelief: "/add_update_answer_mybelief",


    // Ultimate_Assessment
    addUltimateAssessment: "/add_assessment",
    getUltimateAssessment: "/get_assessments_by_user_id",
    deleteUltimateAssessment: "/delete_assessment",
    updateUltimateAssessment: "/update_assessment",

    addpersonalfinance: "/add_personal_finance",
    getpersonalfinance: "/get_personal_finance_by_user_id",
    updatepersonalfinance: "/update_personal_finance",
    deletepersonalfinance: "/delete_personal_finance",

    // myreality
    getsubcategorymyreality: "/get_sub_category_myreality",
    getquestionbycatidsubcatidmyreality: "/get_question_by_catid_subcatid_myreality",
    addupdateanswersmyreality: "/add_update_answers_myreality",

    addlifelinemilestone: "/add_lifeline_milestone",
    getlifeline_milestones: "/get_lifeline_milestones_by_user_id",
    updatelifelinemilestone: "/update_lifeline_milestone",
    deletelifelinemilestone: "/delete_lifeline_milestone",

    // MyFuture
    getsubcategoryfromMyFuture: "/get_sub_category_from_MyFuture",
    getquestionbycatidsubcatidfromMyFuture: "/get_question_by_catid_subcatid_from_MyFuture",

    // bucket
    addbucket: "/add_bucket",
    getbucket: "/getbucket",
    bucket_insert: "/bucket_insert",
    getuniquebucketdatabyuserid: "/get_unique_bucket_data_by_user_id",
    adduniquebucket: "/add_unique_bucket",
    updateduniquebucket: "/updated_unique_bucket",
    deletedatabyidfrombucket: "/delete_data_by_id_from_bucket",
    updateBucketStatus: "/checkbucket",
    getCheckedItems: "/checklist",
    //counter

    get_deshboard_counter: "/get_deshboard_counter",
    get_data_from_table_monthwise: "/get_data_from_table_monthwise",
    get_data_for_chart: "/get_data_for_chart",
    notifications: "/tasksCreatedToday",
    myReport: "/my_report_add_update_challenges",
    getReportData: "/get_reports_datas",
    get_data_from_report: "/get_data_from_report_by_user_id",
    view_habit_notification: "/view_habit_notification",



    //in-process task

    inProcessTask: "/get_dashboard_task",
    update_dashboard_task: "/update_dashboard_task",
    get_data_dashboard_chart_page: "/get_data_dashboard_chart_page",
    get_pos_graph_data: "/get_pos_graph_data",



    //new changes

    get_vision_category: "/get_vision_category",
    add_updte_vision_category: "/add_updte_vision_category",
    get_first_question_answer:'/get_first_question_answer',
    

    //Outcomes
    get_all_category_from_outcomes: '/get_all_category_from_outcomes',
    add_upadate_outcomes: "/add_upadate_outcomes",
    update_check_status_outcomes: "/update_check_status_outcomes",
    get_outcome_for_deshboard: "/get_outcome_for_deshboard",

    //role modal
    add_update_role_model: "/add_update_role_model",
    get_all_role_model_data: "/get_all_role_model_data",


  

    //blueprint

    get_all_for_Life360_blueprint: "/get_all_for_Life360_blueprint",


    get_all_data_for_personal_growth: "/get_all_data_for_personal_growth",
    add_updated_think_i_can_learn: "/add_updated_think_i_can_learn",
    get_all_from_think_i_can_learn: "/get_all_from_think_i_can_learn",
    add_updated_ultimate_assessment: "/add_updated_ultimate_assessment",
    get_all_record_ultimate_assessment: "/get_all_record_ultimate_assessment",

    primary_driver_insert_and_update: "/primary_driver_insert_and_update",
    motivation_style_insert_and_update: "/motivation_style_insert_and_update",
    moved_by_insert_and_update: "/moved_by_insert_and_update",
    get_primary_diver: "/get_primary_diver",
    get_motivation_diver: "/get_motivation_diver",
    get_moved_by_driver: "/get_moved_by_driver",


    insert_update_accomplishments: "/insert_update_accomplishments",
    get_all_accomplishments: "/get_all_accomplishments",

    //check_subscription
    check_subscription: "/check_subscription",
    renew_subscription: "/renew_subscription",

    //disc test

    get_all_question_disc_test_result: "/get_all_question_disc_test_result",
    get_all_disc_test_result: "/get_all_disc_test_result",
    add_update_disc_test_result: "/add_update_disc_test_result",

    add_update_to_natural_disc_test_result: "/add_update_to_natural_disc_test_result",
    get_all_to_natural_disc_test_result: "/get_all_to_natural_disc_test_result",
    get_all_question_to_natural_disc_test_result: "/get_all_question_to_natural_disc_test_result",

    add_update_to_disc_test_disc_test_result:"/add_update_to_disc_test_disc_test_result",
    get_all_to_disc_test_disc_test_result:"/get_all_to_disc_test_disc_test_result",
    get_all_question_to_disc_test_disc_test_result:"/get_all_question_to_disc_test_disc_test_result",

    add_update_to_disc_test_behaviour_test_result:"/add_update_to_disc_test_behaviour_test_result",
    get_all_to_disc_test_behaviour_test_result:"/get_all_to_disc_test_behaviour_test_result",
    get_all_question_to_disc_test_behaviour_test_result:"/get_all_question_to_disc_test_behaviour_test_result",


    add_update_disc_test_SummaryMotivation:"/add_update_disc_test_SummaryMotivation",
    get_all_question_to_disc_test_SummaryMotivation:"/get_all_question_to_disc_test_SummaryMotivation",
    get_all_to_disc_test_SummaryMotivation:"/get_all_to_disc_test_SummaryMotivation",

    add_update_disc_test_details_Motivation:"/add_update_disc_test_details_Motivation",
    get_all_to_disc_test_detailsMotivation:"/get_all_to_disc_test_detailsMotivation",
   get_all_question_to_disc_test_detailsMotivation:"/get_all_question_to_disc_test_detailsMotivation",

    //aplicable checkbox
    get_all_question_to_core_value:"/get_all_question_to_core_value",
    checked_category: "/checked_category",
   

    add_update_personal_os_core_value:"/add_update_personal_os_core_value",
    get_all_to_personal_os_core_value:"/get_all_to_personal_os_core_value",
    check_top5new:"/check_top5new",

    add_description_top5new_data:"/add_description_top5new_data",
    email_exist:"/email_exist"
  },
};

export default API;



export const isSubscribedAccountant = () => {
  const isSubscribed = localStorage.getItem('isSubscriptionValid');
  if (isSubscribed === 'true') {
    return true;
  }
  else {
    return false;
  }
};