import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TestResult from "./myPersonalityTabs/testResult";
import Motivators from "./myPersonalityTabs/motivators";
import Personality from "./myPersonalityTabs/personality";
import Assesment from "./myPersonalityTabs/assesment";
import Drivers from "./myPersonalityTabs/drivers";
import { useNavigate } from "react-router-dom";

const PersonalityProfile = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('DISC Test');
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "My Personality");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container fluid={true}>
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                id="justify-tab-example"
                className="border-tab nav-primary mb-3"
                justify
            >
                {tabs.map(tab => (
                    <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                        {getTabContent(tab.sub_category, activeTab)}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
};

const getTabContent = (tabName, activeTab) => {
    switch (tabName) {
        case 'DISC Test':
            return <TestResult activeTab={activeTab} />;
        case 'Myer Briggs':
            return <Motivators activeTab={activeTab} />;
        case '16 Personalities':
            return <Personality activeTab={activeTab} />;
        case 'Giftings':
            return <Assesment activeTab={activeTab} />;
        case 'Motivations & Drivers':
            return <Drivers activeTab={activeTab} />;
        default:
            return null;
    }
};

export default PersonalityProfile;
