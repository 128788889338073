import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Logosvg from '../../../assets/images/logo/personalos.png';
import logo_compact from '../../../assets/images/logo/compact-logo.png';
import { MENUITEMS } from '../../../components/common/sidebar-component/menu';
import { useTranslation } from 'react-i18next';
import configDB from '../../../data/customizer/config';
import { FaAngleRight } from 'react-icons/fa';

const Sidebar = (props) => {
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [mainmenu, setMainMenu] = useState({ mainmenu: MENUITEMS });
    const wrapper = configDB.data.settings.sidebar.wrapper;
    const layout = useSelector(content => content.Customizer.layout);
    const { t } = useTranslation();

    const [currentUrl, setCurrentUrl] = useState(window.location.pathname);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        setCurrentUrl(window.location.pathname);

        MENUITEMS.forEach(items => {
            if (items.path === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            items.children.forEach(subItems => {
                if (subItems.path === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.forEach(subSubItems => {
                    if (subSubItems.path === currentUrl) {
                        setNavActive(subSubItems)
                    }
                })
            })
        });

        const timeout = setTimeout(() => {
            const elmnt = document.getElementById("myDIV");
            const menuWidth = elmnt.offsetWidth;
            if (menuWidth > window.innerWidth) {
                setHideRightArrow(false);
                setHideLeftArrowRTL(false);
            } else {
                setHideRightArrow(true);
                setHideLeftArrowRTL(true);
            }
        }, 500)

        return () => {
            window.removeEventListener('resize', handleResize)
            clearTimeout(timeout)
        }

    }, [currentUrl]);

    function handleResize() {
        setWidth(window.innerWidth - 310);
    }

    const setNavActive = (item) => {
        MENUITEMS.forEach(menuItem => {
            if (menuItem !== item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.forEach(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }

    const toggletNavActive = (item) => {
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
        }
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += (-width));
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }

    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }

    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        <a href={`/dashboard/default`}>
                            <img className="Logosvg" src={Logosvg} alt="logo-svg" />
                        </a>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? layout === 'rtl' ? 
                            { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                        <li className={`left-arrow ${layout === 'rtl' ? hideLeftArrowRTL ? 'd-none' : '' : hideLeftArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToLeftRTL : scrollToLeft}><i className="fa fa-angle-left"></i></li>
                        {
                            mainmenu?.mainmenu?.map((menuItem, i) =>
                                <li className={`${menuItem.active ? 'active' : ''} ${menuItem.title === 'Home' ? 'home-section' : ''} ${menuItem.title === 'Personal Growth' ? 'personal-growth-section' : ''} ${menuItem.title === 'My Life Plan' ? 'my-life-plan-section' : ''} ${menuItem.title === 'Personal OS' ? 'personal-os-section' : ''} ${menuItem.title === 'My Belief System' ? 'my-belief-system-section' : ''} ${menuItem.title === 'My Reality' ? 'my-reality-section' : ''} ${menuItem.title === 'My Future' ? 'my-future-section' : ''} ${menuItem.title === 'My Reports' ? 'my-reports-section' : ''} ${menuItem.title === 'My Notes' ? 'my-notes-section' : ''}`} key={i}>

                                    {(menuItem.sidebartitle) ? 
                                        <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                        : ''}
                                    {(menuItem.type === 'sub') ?
                                        <a className="sidebar-header" onClick={() => toggletNavActive(menuItem)}>
                                            <menuItem.icon />
                                            <span>{t(menuItem.title)}</span>
                                            <i className="fa fa-angle-right pull-right"> <FaAngleRight /></i>
                                        </a>
                                        : ''}
                                    {(menuItem.type === 'link') ?
                                        <a
                                            href={`${menuItem.path}`}
                                            className={`sidebar-header ${menuItem.active && currentUrl !== '/users/userEdit' ? 'active' : ''}`}
                                            onClick={() => !menuItem.active && toggletNavActive(menuItem)}
                                            style={(menuItem.active && currentUrl === '/users/userEdit') ? { 'borderBottom': '1px solid rgba(0, 0, 0, 0.12)' } : {}}
                                        >
                                            <menuItem.icon />
                                            <span>{t(menuItem.title)}</span>
                                        </a>
                                        : ''}
                                    {(menuItem.children) ?
                                        <ul className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}>
                                            {
                                                menuItem.children.map((subItem, i) =>
                                                    <li key={i} className={`${subItem.active ? 'active' : ''}`}>
                                                        {(subItem.type === 'sub') ?
                                                            <a onClick={() => toggletNavActive(subItem)}>
                                                                <span>{t(subItem.title)}</span>
                                                                <i className="fa fa-angle-right pull-right"> <FaAngleRight /></i>
                                                            </a>
                                                            : ''}
                                                        {(subItem.type === 'link') ?
                                                            <a
                                                                href={`${subItem.path}`}
                                                                className={`sidebar-header ${subItem.active ? 'active' : ''}`}
                                                                onClick={() => !subItem.active && toggletNavActive(subItem)}
                                                            >
                                                                <span>{t(subItem.title)}</span>
                                                            </a>
                                                            : ''}
                                                        {(subItem.children) ?
                                                            <ul className={`sidebar-submenu ${subItem.active ? 'menu-open' : ''}`}>
                                                                {
                                                                    subItem.children.map((subSubItem, i) =>
                                                                        <li key={i} className={`${subSubItem.active ? 'active' : ''}`}>
                                                                            {(subSubItem.type === 'sub') ?
                                                                                <a onClick={() => toggletNavActive(subSubItem)}>
                                                                                    <span>{t(subSubItem.title)}</span>
                                                                                    <i className="fa fa-angle-right pull-right"> <FaAngleRight /></i>
                                                                                </a>
                                                                                : ''}
                                                                            {(subSubItem.type === 'link') ?
                                                                                <a
                                                                                    href={`${subSubItem.path}`}
                                                                                    className={`sidebar-header ${subSubItem.active ? 'active' : ''}`}
                                                                                    onClick={() => !subSubItem.active && toggletNavActive(subSubItem)}
                                                                                >
                                                                                    <span>{t(subSubItem.title)}</span>
                                                                                </a>
                                                                                : ''}
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                            : ''}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        : ''}
                                </li>
                            )
                        }
                        <li className={`right-arrow ${layout === 'rtl' ? hideRightArrowRTL ? 'd-none' : '' : hideRightArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToRightRTL : scrollToRight}><i className="fa fa-angle-right"></i></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default Sidebar;
