import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container, Nav, NavItem, Tab, TabContent, TabPane, Tabs } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LifeWheelteb from "./lifewheelteb/lifewheel";
import Action from "./lifewheelteb/action";
import Reflection from "./lifewheelteb/reflection";

const BeliefSystem = () => {
  const navigate = useNavigate()
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState('My Life Wheel');

  useEffect(() => {
    fetchTabs();
  }, []);
  useEffect(() => {
    if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    
    }, []);
  const fetchTabs = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('main_category_name', "Life Wheel");

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getsubcategorymyreality}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );

      if (response.data.status === "success") {
        setTabs(response.data.sub_category);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch tabs.");
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    clearValidationErrors();
  };

  const clearValidationErrors = () => {
    window.dispatchEvent(new Event('clearValidationErrors'));
  };



  return (
    <>
      <Container fluid>
        {/* 
        <div className="d-lg-none m-15 d-flex col-4">
          <select className="form-select" value={activeTab} onChange={handleSelect}>
            {tabs.map(tab => (
              <option key={tab.id} value={tab.sub_category}>{tab.sub_category}</option>
            ))}
          </select>
        </div>


        <div className="d-none d-lg-block"> */}
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          defaultActiveKey="My Life Wheel"
          id="justify-tab-example"
          className="border-tab nav-primary mb-3"
          justify
        >
          {tabs.map(tab => (
            <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
              {getTabContent(tab.sub_category)}
            </Tab>
          ))}
        </Tabs>
        {/*    </div>


        {getTabContent(activeTab)} */}
      </Container>
    </>
  );
};

const getTabContent = (tabName) => {
  switch (tabName) {
    case 'My Life Wheel':
      return <LifeWheelteb />;
    case 'Reflection':
      return <Reflection />;
    case 'Action':
      return <Action />;
    default:
      return null;
  }
};

export default BeliefSystem;
