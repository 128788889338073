import React, { Fragment, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { Spinner } from "react-bootstrap";
import API, { isSubscribedAccountant } from '../../../utils.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const BarChartdashboard = () => {
    const navigate = useNavigate();
    const uid = localStorage.getItem("user_id");
    const [chartData, setChartData] = useState([
        ['Category', 'Value', { role: 'style' }, { role: 'annotation' }],
    ]);
    const [loading, setLoading] = useState(true);  // Add loading state

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
     return;
     
        }
    }, []);

    // Define an array of colors for the bars
    const barColors = ['#F94144', '#F3722C', '#F8961E', '#F9844A', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E','#577590','#  277DA1' ];

    const options = {
        hAxis: {
            title: 'Life Area',
            slantedText: false,
            textStyle: {
                fontSize: 10,
            },
            titleTextStyle: {
                fontSize: 14,
            },
            maxAlternation: 1, // Change to 0 if you want all labels displayed
            showTextEvery: 1, // Show every label (or adjust to show fewer)
        },
        vAxis: {
            title: 'Planning Progress Complete %',
            minValue: 0,
            textStyle: {
                fontSize: 12, // Font size of labels
            },
            titleTextStyle: {
                fontSize: 14, // Font size of the axis title
            },
        },
        chartArea: {
            width: '95%',
            height: '75%',
        },
        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 14,
                color: '#000',
                auraColor: 'none',
            },
        },
        tooltip: {
            isHtml: true,
            trigger: 'focus',
            textStyle: {
                fontSize: 12,
                color: '#ffff',
                auraColor: 'none',
            },
            formatter: (value) => `<b>${value}%</b>`,
        },
        legend: {
            position: 'none',
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchChartData();
    }, []);

    const fetchChartData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_pos_graph_data}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                const data = response.data.data;
                const formattedData = [['Category', 'Progress', { role: 'style' }, { role: 'annotation' }]]; // Added 'style' role for color
                let colorIndex = 0;
                for (const [category, value] of Object.entries(data)) {
                    formattedData.push([category, Number(value), barColors[colorIndex % barColors.length], `${value}%`]);
                    colorIndex++;
                }
                setChartData(formattedData);
            } else {
                toast.error(response.data.message || 'Failed to fetch chart data');
            }
        } catch (error) {
            console.error('Error fetching chart data:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching data
        }
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="card">
                            <div className="card-header">
                                <h5>Life Plan Progress</h5>
                            </div>
                            <div className="chart-container" style={{ width: '100%', height: '400px' }}>
                                {loading ? (  // Show loader while loading
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                ) : (
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height={windowWidth < 768 ? '300px' : '400px'}
                                        data={chartData}
                                        options={options}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BarChartdashboard;
